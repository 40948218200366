import { Button, Kind } from '@thanx/uikit/button'
import { RadioGroup } from '@thanx/uikit/radio'
import { Text } from '@thanx/uikit/text'
import RichSelector from 'components/Form/RichSelector'
import Book from 'components/Icons/Book'
import BullHornRectangleIcon from 'components/Icons/BullHornRectangle'
import BullsEyeRectangleIcon from 'components/Icons/BullsEyeRectangle'
import RotateRectangleIcon from 'components/Icons/RotateRectangle'
import Modal from 'components/ModalContainer'
import { buildTranslate } from 'locales'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { push } from 'react-router-redux'

const t = buildTranslate('thanx_campaigns.newCampaign')

const routes = {
  targeted: '/thanx_campaigns/targeted',
  message: '/thanx_campaigns_m',
  automated: '/thanx_campaigns/automated',
}

type Props = {
  isOpen: boolean
  onClose: () => void
}

const NewCampaignModal: React.FC<Props> = ({ isOpen, onClose }) => {
  const [selected, setSelected] = useState<string>('targeted')
  const dispatch = useDispatch()

  const handleContinue = () => {
    dispatch(push(routes[selected]))
    onClose()
  }

  const handleClick = event => {
    const type = event.currentTarget.querySelector('input:first-of-type').value
    setSelected(type)
  }

  return (
    <Modal
      isOpen={isOpen}
      title={t('title')}
      onClose={onClose}
      onProceed={handleContinue}
      proceedText={t('continue')}
      buttonGroupChildren={
        <div className="flex-1">
          <Link
            to={{
              pathname:
                'https://help.thanx.com/hc/en-us/categories/9020361665303-Marketing',
            }}
            target="_blank"
          >
            <Button kind={Kind.MINIMAL} size="small" className="p-0">
              {t('learn_more')}
              <Book className="ml-xs" />
            </Button>
          </Link>
        </div>
      }
    >
      <RadioGroup value={selected} className="mt-xl">
        <RichSelector
          value="targeted"
          image={<BullsEyeRectangleIcon />}
          imageClassName="ml-xs"
          className="mb-s"
          onClick={handleClick}
        >
          <Text.Header4 bold className="mt-0">
            {t('targeted.title')}
          </Text.Header4>
          <Text.BodyText4 tag="p" className="mb-0" color="grey70">
            {t('targeted.description')}
          </Text.BodyText4>
        </RichSelector>

        <RichSelector
          value="message"
          image={<BullHornRectangleIcon />}
          imageClassName="ml-xs"
          className="mb-s"
          onClick={handleClick}
        >
          <Text.Header4 bold className="mt-0">
            {t('message.title')}
          </Text.Header4>
          <Text.BodyText4 tag="p" className="mb-0" color="grey70">
            {t('message.description')}
          </Text.BodyText4>
        </RichSelector>

        <RichSelector
          value="automated"
          image={<RotateRectangleIcon />}
          imageClassName="ml-xs"
          onClick={handleClick}
        >
          <Text.Header4 bold className="mt-0">
            {t('automated.title')}
          </Text.Header4>
          <Text.BodyText4 tag="p" className="mb-0" color="grey70">
            {t('automated.description')}
          </Text.BodyText4>
        </RichSelector>
      </RadioGroup>
    </Modal>
  )
}

export default NewCampaignModal
