export default {
  title: 'Start a new campaign',
  continue: 'Get started',
  cancel: 'Cancel',
  targeted: {
    title: 'Targeted campaign',
    description: 'Send a campaign once to a specific segment of customers',
  },
  message: {
    title: 'Message to everyone',
    description: 'Send a campaign once to everyone',
  },
  automated: {
    title: 'Automated campaign',
    description:
      'Set up an ongoing campaign that will send automatically when a customer enters a segment',
  },
  learn_more: 'Learn more',
}
